module.exports = {
  CLEAR: "onboarding/CLEAR",
  VERIFY_IDENTITY_REQUEST: "onboarding/VERIFY_IDENTITY_REQUEST",
  VERIFY_IDENTITY_ERROR: "onboarding/VERIFY_IDENTITY_ERROR",
  VERIFY_IDENTITY_SUCCESS: "onboarding/VERIFY_IDENTITY_SUCCESS",
  CONFIRM_IDENTITY_REQUEST: "onboarding/CONFIRM_IDENTITY_REQUEST",
  CONFIRM_IDENTITY_ERROR: "onboarding/CONFIRM_IDENTITY_ERROR",
  CONFIRM_IDENTITY_SUCCESS: "onboarding/CONFIRM_IDENTITY_SUCCESS",
  SUBMIT_OTP_REQUEST: "onboarding/SUBMIT_OTP_REQUEST",
  SUBMIT_OTP_ERROR: "onboarding/SUBMIT_OTP_ERROR",
  SUBMIT_OTP_SUCCESS: "onboarding/SUBMIT_OTP_SUCCESS",
  SUBMIT_PIN_REQUEST: "onboarding/SUBMIT_PIN_REQUEST",
  SUBMIT_PIN_ERROR: "onboarding/SUBMIT_PIN_ERROR",
  SUBMIT_PIN_SUCCESS: "onboarding/SUBMIT_PIN_SUCCESS",
}