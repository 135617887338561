import React from "react";
import "./style.scss";
import Modal from "#/components/Modal";
const MobileTransaction = () => {
  return (
    <div className="mobile-transaction">
      <Modal>
        <div className="text-right pb-3" style={{ height: "40px" }}>
          {/* <img
            src={require("#/assets/icons/close.svg")}
            className="cursor-pointer"
            alt="close"
          /> */}
        </div>
        <div className="px-5">
          <div className="d-flex justify-content-center">
            <img
              src={require("#/assets/icons/done.svg")}
              alt="bank"
              className="pb-3"
            />
          </div>
          <div className="text-center">
            <div className="mb-3">
              <h5 className="text-success font-bolder mobile-text-width">
                Transaction Successful
              </h5>
            </div>
            <p>Your transaction has been successfully made</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MobileTransaction;
