module.exports = {
  CLEAR_ERROR: "blog/CLEAR_ERROR",
  CLEAR_DATA: "blog/CLEAR_DATA",
  GET_RECENT_POSTS_REQUEST: "blog/GET_RECENT_POSTS_REQUEST",
  GET_RECENT_POSTS_ERROR: "blog/GET_RECENT_POSTS_ERROR",
  GET_RECENT_POSTS_SUCCESS: "blog/GET_RECENT_POSTS_SUCCESS",
  GET_LATEST_POSTS_REQUEST: "blog/GET_LATEST_POSTS_REQUEST",
  GET_LATEST_POSTS_ERROR: "blog/GET_LATEST_POSTS_ERROR",
  GET_LATEST_POSTS_SUCCESS: "blog/GET_LATEST_POSTS_SUCCESS",
  GET_TRENDING_POSTS_REQUEST: "blog/GET_TRENDING_POSTS_REQUEST",
  GET_TRENDING_POSTS_ERROR: "blog/GET_TRENDING_POSTS_ERROR",
  GET_TRENDING_POSTS_SUCCESS: "blog/GET_TRENDING_POSTS_SUCCESS",
  GET_SINGLE_POST_REQUEST: "blog/GET_SINGLE_POST_REQUEST",
  GET_SINGLE_POST_ERROR: "blog/GET_SINGLE_POST_ERROR",
  GET_SINGLE_POST_SUCCESS: "blog/GET_SINGLE_POST_SUCCESS",
  GET_COMMENTS_REQUEST: "blog/GET_COMMENTS_REQUEST",
  GET_COMMENTS_ERROR: "blog/GET_COMMENTS_ERROR",
  GET_COMMENTS_SUCCESS: "blog/GET_COMMENTS_SUCCESS",
  POST_COMMENT_REQUEST: "blog/POST_COMMENT_REQUEST",
  POST_COMMENT_ERROR: "blog/POST_COMMENT_ERROR",
  POST_COMMENT_SUCCESS: "blog/POST_COMMENT_SUCCESS",
  REPLY_COMMENT_REQUEST: "blog/REPLY_COMMENT_REQUEST",
  REPLY_COMMENT_ERROR: "blog/REPLY_COMMENT_ERROR",
  REPLY_COMMENT_SUCCESS: "blog/REPLY_COMMENT_SUCCESS",
  LIKE_COMMENT_REQUEST: "blog/LIKE_COMMENT_REQUEST",
  LIKE_COMMENT_ERROR: "blog/LIKE_COMMENT_ERROR",
  LIKE_COMMENT_SUCCESS: "blog/LIKE_COMMENT_SUCCESS",
  GET_CATEGORY_POSTS_REQUEST: "blog/GET_CATEGORY_POSTS_REQUEST",
  GET_CATEGORY_POSTS_ERROR: "blog/GET_CATEGORY_POSTS_ERROR",
  GET_CATEGORY_POSTS_SUCCESS: "blog/GET_CATEGORY_POSTS_SUCCESS",
  FILTER_POSTS_REQUEST: "blog/FILTER_POSTS_REQUEST",
  FILTER_POSTS_ERROR: "blog/FILTER_POSTS_ERROR",
  FILTER_POSTS_SUCCESS: "blog/FILTER_POSTS_SUCCESS",
};
