module.exports = {
  CLEAR_ERROR: "wallet/CLEAR_ERROR",
  CLEAR_DATA: "wallet/CLEAR_DATA",
  DEPOSIT_REQUEST: "wallet/DEPOSIT_REQUEST",
  DEPOSIT_ERROR: "wallet/DEPOSIT_ERROR",
  DEPOSIT_SUCCESS: "wallet/DEPOSIT_SUCCESS",
  DEPOSIT_CARD_REQUEST: "wallet/DEPOSIT_CARD_REQUEST",
  DEPOSIT_CARD_ERROR: "wallet/DEPOSIT_CARD_ERROR",
  DEPOSIT_CARD_SUCCESS: "wallet/DEPOSIT_CARD_SUCCESS",
  GET_ALL_CARDS_REQUEST: "wallet/GET_ALL_CARDS_REQUEST",
  GET_ALL_CARDS_ERROR: "wallet/GET_ALL_CARDS_ERROR",
  GET_ALL_CARDS_SUCCESS: "wallet/GET_ALL_CARDS_SUCCESS",
  GET_WALLET_DETAILS_REQUEST: "wallet/GET_WALLET_DETAILS_REQUEST",
  GET_WALLET_DETAILS_ERROR: "wallet/GET_WALLET_DETAILS_ERROR",
  GET_WALLET_DETAILS_SUCCESS: "wallet/GET_WALLET_DETAILS_SUCCESS",
  INITIALIZE_WITHDRAWAL_REQUEST: "wallet/INITIALIZE_WITHDRAWAL_REQUEST",
  INITIALIZE_WITHDRAWAL_ERROR: "wallet/INITIALIZE_WITHDRAWAL_ERROR",
  INITIALIZE_WITHDRAWAL_SUCCESS: "wallet/INITIALIZE_WITHDRAWAL_SUCCESS",
  CONFIRM_WITHDRAWAL_REQUEST: "wallet/CONFIRM_WITHDRAWAL_REQUEST",
  CONFIRM_WITHDRAWAL_ERROR: "wallet/CONFIRM_WITHDRAWAL_ERROR",
  CONFIRM_WITHDRAWAL_SUCCESS: "wallet/CONFIRM_WITHDRAWAL_SUCCESS",
  GET_TRANSACTION_HISTORY_REQUEST: "wallet/GET_TRANSACTION_HISTORY_REQUEST",
  GET_TRANSACTION_HISTORY_ERROR: "wallet/GET_TRANSACTION_HISTORY_ERROR",
  GET_TRANSACTION_HISTORY_SUCCESS: "wallet/GET_TRANSACTION_HISTORY_SUCCESS",
};
