module.exports = {
  CLEAR: "login/CLEAR",
  CLEAR_ERROR: "login/CLEAR_ERROR",
  LOGIN_REQUEST: "login/LOGIN_REQUEST",
  LOGIN_ERROR: "login/LOGIN_ERROR",
  LOGIN_SUCCESS: "login/LOGIN_SUCCESS",
  SEND_RESET_LINK_REQUEST: "login/SEND_RESET_LINK_REQUEST",
  SEND_RESET_LINK_ERROR: "login/SEND_RESET_LINK_ERROR",
  SEND_RESET_LINK_SUCCESS: "login/SEND_RESET_LINK_SUCCESS",
  RESET_PASSWORD_REQUEST: "login/RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_ERROR: "login/RESET_PASSWORD_ERROR",
  RESET_PASSWORD_SUCCESS: "login/RESET_PASSWORD_SUCCESS",
}