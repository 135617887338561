module.exports = {
  CLEAR_DATA: "support/CLEAR_DATA",
  CLEAR_ERROR: "support/CLEAR_ERROR",
  GET_TICKETS_REQUEST: "support/GET_TICKETS_REQUEST",
  GET_TICKETS_ERROR: "support/GET_TICKETS_ERROR",
  GET_TICKETS_SUCCESS: "support/GET_TICKETS_SUCCESS",
  GET_SINGLE_TICKET_REQUEST: "support/GET_SINGLE_TICKET_REQUEST",
  GET_SINGLE_TICKET_ERROR: "support/GET_SINGLE_TICKET_ERROR",
  GET_SINGLE_TICKET_SUCCESS: "support/GET_SINGLE_TICKET_SUCCESS",
  GET_ALL_USER_GUIDES_REQUEST: "support/GET_ALL_USER_GUIDES_REQUEST",
  GET_ALL_USER_GUIDES_ERROR: "support/GET_ALL_USER_GUIDES_ERROR",
  GET_ALL_USER_GUIDES_SUCCESS: "support/GET_ALL_USER_GUIDES_SUCCESS",
  CREATE_NEW_TICKET_REQUEST: "support/CREATE_NEW_TICKET_REQUEST",
  CREATE_NEW_TICKET_ERROR: "support/CREATE_NEW_TICKET_ERROR",
  CREATE_NEW_TICKET_SUCCESS: "support/CREATE_NEW_TICKET_SUCCESS",
  SEARCH_TICKETS_REQUEST: "support/SEARCH_TICKETS_REQUEST",
  SEARCH_TICKETS_ERROR: "support/SEARCH_TICKETS_ERROR",
  SEARCH_TICKETS_SUCCESS: "support/SEARCH_TICKETS_SUCCESS",
  SEND_MESSAGE_REQUEST: "support/SEND_MESSAGE_REQUEST",
  SEND_MESSAGE_ERROR: "support/SEND_MESSAGE_ERROR",
  SEND_MESSAGE_SUCCESS: "support/SEND_MESSAGE_SUCCESS",
  GET_ALL_FAQS_REQUEST: "support/GET_ALL_FAQS_REQUEST",
  GET_ALL_FAQS_ERROR: "support/GET_ALL_FAQS_ERROR",
  GET_ALL_FAQS_SUCCESS: "support/GET_ALL_FAQS_SUCCESS",
}