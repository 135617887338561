module.exports = {
  CLEAR_DATA: "ppi/CLEAR_DATA",
  CLEAR_ERROR: "ppi/CLEAR_ERROR",
  GET_ACTIVE_PPI_REQUEST: "ppi/GET_ACTIVE_PPI_REQUEST",
  GET_ACTIVE_PPI_ERROR: "ppi/GET_ACTIVE_PPI_ERROR",
  GET_ACTIVE_PPI_SUCCESS: "ppi/GET_ACTIVE_PPI_SUCCESS",
  GET_SINGLE_PPI_REQUEST: "ppi/GET_SINGLE_PPI_REQUEST",
  GET_SINGLE_PPI_ERROR: "ppi/GET_SINGLE_PPI_ERROR",
  GET_SINGLE_PPI_SUCCESS: "ppi/GET_SINGLE_PPI_SUCCESS",
  SUBMIT_MUTUAL_FORM_REQUEST: "ppi/SUBMIT_MUTUAL_FORM_REQUEST",
  SUBMIT_MUTUAL_FORM_ERROR: "ppi/SUBMIT_MUTUAL_FORM_ERROR",
  SUBMIT_MUTUAL_FORM_SUCCESS: "ppi/SUBMIT_MUTUAL_FORM_SUCCESS",
  SEND_FORM_TO_EMAIL_REQUEST: "ppi/SEND_FORM_TO_EMAIL_REQUEST",
  SEND_FORM_TO_EMAIL_ERROR: "ppi/SEND_FORM_TO_EMAIL_ERROR",
  SEND_FORM_TO_EMAIL_SUCCESS: "ppi/SEND_FORM_TO_EMAIL_SUCCESS",
}