module.exports = {
  CLEAR_ERROR: "investment/CLEAR_ERROR",
  CLEAR_DATA: "investment/CLEAR_DATA",
  GET_ALL_INVESTMENTS_REQUEST: "investment/GET_ALL_INVESTMENTS_REQUEST",
  GET_ALL_INVESTMENTS_ERROR: "investment/GET_ALL_INVESTMENTS_ERROR",
  GET_ALL_INVESTMENTS_SUCCESS: "investment/GET_ALL_INVESTMENTS_SUCCESS",
  BOOK_NEW_INVESTMENT_REQUEST: "investment/BOOK_NEW_INVESTMENT_REQUEST",
  BOOK_NEW_INVESTMENT_ERROR: "investment/BOOK_NEW_INVESTMENT_ERROR",
  BOOK_NEW_INVESTMENT_SUCCESS: "investment/BOOK_NEW_INVESTMENT_SUCCESS",
  BOOK_INVESTMENT_WITH_PAY_REQUEST: "investment/BOOK_INVESTMENT_WITH_PAY_REQUEST",
  BOOK_INVESTMENT_WITH_PAY_ERROR: "investment/BOOK_INVESTMENT_WITH_PAY_ERROR",
  BOOK_INVESTMENT_WITH_PAY_SUCCESS: "investment/BOOK_INVESTMENT_WITH_PAY_SUCCESS",
  GET_INVESTMENT_DETAILS_REQUEST: "investment/GET_INVESTMENT_DETAILS_REQUEST",
  GET_INVESTMENT_DETAILS_ERROR: "investment/GET_INVESTMENT_DETAILS_ERROR",
  GET_INVESTMENT_DETAILS_SUCCESS: "investment/GET_INVESTMENT_DETAILS_SUCCESS",
  LIQUIDATE_INVESTMENT_REQUEST: "investment/LIQUIDATE_INVESTMENT_REQUEST",
  LIQUIDATE_INVESTMENT_ERROR: "investment/LIQUIDATE_INVESTMENT_ERROR",
  LIQUIDATE_INVESTMENT_SUCCESS: "investment/LIQUIDATE_INVESTMENT_SUCCESS",
  GET_LIQUIDATION_DETAILS_REQUEST: "investment/GET_LIQUIDATION_DETAILS_REQUEST",
  GET_LIQUIDATION_DETAILS_ERROR: "investment/GET_LIQUIDATION_DETAILS_ERROR",
  GET_LIQUIDATION_DETAILS_SUCCESS: "investment/GET_LIQUIDATION_DETAILS_SUCCESS",
  GET_RECOMMENDED_INVESTMENTS_REQUEST: "investment/GET_RECOMMENDED_INVESTMENTS_REQUEST",
  GET_RECOMMENDED_INVESTMENTS_ERROR: "investment/GET_RECOMMENDED_INVESTMENTS_ERROR",
  GET_RECOMMENDED_INVESTMENTS_SUCCESS: "investment/GET_RECOMMENDED_INVESTMENTS_SUCCESS",
  CALCULATE_INVESTMENT_REQUEST: "investment/CALCULATE_INVESTMENT_REQUEST",
  CALCULATE_INVESTMENT_ERROR: "investment/CALCULATE_INVESTMENT_ERROR",
  CALCULATE_INVESTMENT_SUCCESS: "investment/CALCULATE_INVESTMENT_SUCCESS",
  DISABLE_AUTOCHARGE_REQUEST: "investment/DISABLE_AUTOCHARGE_REQUEST",
  DISABLE_AUTOCHARGE_ERROR: "investment/DISABLE_AUTOCHARGE_ERROR",
  DISABLE_AUTOCHARGE_SUCCESS: "investment/DISABLE_AUTOCHARGE_SUCCESS",
};
