module.exports = {
  CLEAR_ERROR: "profile/CLEAR_ERROR",
  CLEAR_PIN_ERROR: "profile/CLEAR_PIN_ERROR",
  CLEAR_DATA: "profile/CLEAR_DATA",
  ADD_EMPLOYMENT_REQUEST: "profile/ADD_EMPLOYMENT_REQUEST",
  ADD_EMPLOYMENT_ERROR: "profile/ADD_EMPLOYMENT_ERROR",
  ADD_EMPLOYMENT_SUCCESS: "profile/ADD_EMPLOYMENT_SUCCESS",
  ADD_NEXT_OF_KIN_REQUEST: "profile/ADD_NEXT_OF_KIN_REQUEST",
  ADD_NEXT_OF_KIN_ERROR: "profile/ADD_NEXT_OF_KIN_ERROR",
  ADD_NEXT_OF_KIN_SUCCESS: "profile/ADD_NEXT_OF_KIN_SUCCESS",
  ADD_POLITICAL_STATUS_REQUEST: "profile/ADD_POLITICAL_STATUS_REQUEST",
  ADD_POLITICAL_STATUS_ERROR: "profile/ADD_POLITICAL_STATUS_ERROR",
  ADD_POLITICAL_STATUS_SUCCESS: "profile/ADD_POLITICAL_STATUS_SUCCESS",
  ADD_BIODATA_REQUEST: "profile/ADD_BIODATA_REQUEST",
  ADD_BIODATA_ERROR: "profile/ADD_BIODATA_ERROR",
  ADD_BIODATA_SUCCESS: "profile/ADD_BIODATA_SUCCESS",
  GET_USER_PROFILE_REQUEST: "profile/GET_USER_PROFILE_REQUEST",
  GET_USER_PROFILE_ERROR: "profile/GET_USER_PROFILE_ERROR",
  GET_USER_PROFILE_SUCCESS: "profile/GET_USER_PROFILE_SUCCESS",
  ADD_PROFILE_PHOTO_REQUEST: "profile/ADD_PROFILE_PHOTO_REQUEST",
  ADD_PROFILE_PHOTO_ERROR: "profile/ADD_PROFILE_PHOTO_ERROR",
  ADD_PROFILE_PHOTO_SUCCESS: "profile/ADD_PROFILE_PHOTO_SUCCESS",
  UPDATE_KYC_REQUEST: "profile/UPDATE_KYC_REQUEST",
  UPDATE_KYC_ERROR: "profile/UPDATE_KYC_ERROR",
  UPDATE_KYC_SUCCESS: "profile/UPDATE_KYC_SUCCESS",
  GET_ALL_BANKS_REQUEST: "profile/GET_ALL_BANKS_REQUEST",
  GET_ALL_BANKS_ERROR: "profile/GET_ALL_BANKS_ERROR",
  GET_ALL_BANKS_SUCCESS: "profile/GET_ALL_BANKS_SUCCESS",
  ADD_BANK_DETAILS_REQUEST: "profile/ADD_BANK_DETAILS_REQUEST",
  ADD_BANK_DETAILS_ERROR: "profile/ADD_BANK_DETAILS_ERROR",
  ADD_BANK_DETAILS_SUCCESS: "profile/ADD_BANK_DETAILS_SUCCESS",
  UPDATE_PIN_REQUEST: "profile/UPDATE_PIN_REQUEST",
  UPDATE_PIN_ERROR: "profile/UPDATE_PIN_ERROR",
  UPDATE_PIN_SUCCESS: "profile/UPDATE_PIN_SUCCESS",
  JOIN_SEGMENT_REQUEST: "profile/JOIN_SEGMENT_REQUEST",
  JOIN_SEGMENT_ERROR: "profile/JOIN_SEGMENT_ERROR",
  JOIN_SEGMENT_SUCCESS: "profile/JOIN_SEGMENT_SUCCESS",
  GET_SEGMENT_QUESTIONS_REQUEST: "profile/GET_SEGMENT_QUESTIONS_REQUEST",
  GET_SEGMENT_QUESTIONS_ERROR: "profile/GET_SEGMENT_QUESTIONS_ERROR",
  GET_SEGMENT_QUESTIONS_SUCCESS: "profile/GET_SEGMENT_QUESTIONS_SUCCESS",
  GET_RISK_QUESTIONS_REQUEST: "profile/GET_RISK_QUESTIONS_REQUEST",
  GET_RISK_QUESTIONS_ERROR: "profile/GET_RISK_QUESTIONS_ERROR",
  GET_RISK_QUESTIONS_SUCCESS: "profile/GET_RISK_QUESTIONS_SUCCESS",
  SET_RISK_QUESTIONS_REQUEST: "profile/SET_RISK_QUESTIONS_REQUEST",
  SET_RISK_QUESTIONS_ERROR: "profile/SET_RISK_QUESTIONS_ERROR",
  SET_RISK_QUESTIONS_SUCCESS: "profile/SET_RISK_QUESTIONS_SUCCESS",
}