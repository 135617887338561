module.exports = {
  CLEAR: "register/CLEAR",
  CLEAR_ERROR: "register/CLEAR_ERROR",
  REGISTER_REQUEST: "register/REGISTER_REQUEST",
  REGISTER_ERROR: "register/REGISTER_ERROR",
  REGISTER_SUCCESS: "register/REGISTER_SUCCESS",
  VERIFY_EMAIL_REQUEST: "register/VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_ERROR: "register/VERIFY_EMAIL_ERROR",
  VERIFY_EMAIL_SUCCESS: "register/VERIFY_EMAIL_SUCCESS",
  RESEND_TOKEN_REQUEST: "register/RESEND_TOKEN_REQUEST",
  RESEND_TOKEN_ERROR: "register/RESEND_TOKEN_ERROR",
  RESEND_TOKEN_SUCCESS: "register/RESEND_TOKEN_SUCCESS",
}