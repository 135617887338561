module.exports = {
  CLEAR_DATA: "portfolio/CLEAR_DATA",
  CLEAR_ERROR: "portfolio/CLEAR_ERROR",
  FETCH_PORTFOLIO_REQUEST: "portfolio/FETCH_PORTFOLIO_REQUEST",
  FETCH_PORTFOLIO_ERROR: "portfolio/FETCH_PORTFOLIO_ERROR",
  FETCH_PORTFOLIO_SUCCESS: "portfolio/FETCH_PORTFOLIO_SUCCESS",
  FETCH_SINGLE_INVESTMENT_REQUEST: "portfolio/FETCH_SINGLE_INVESTMENT_REQUEST",
  FETCH_SINGLE_INVESTMENT_ERROR: "portfolio/FETCH_SINGLE_INVESTMENT_ERROR",
  FETCH_SINGLE_INVESTMENT_SUCCESS: "portfolio/FETCH_SINGLE_INVESTMENT_SUCCESS",
  TOP_UP_INVESTMENT_REQUEST: "portfolio/TOP_UP_INVESTMENT_REQUEST",
  TOP_UP_INVESTMENT_ERROR: "portfolio/TOP_UP_INVESTMENT_ERROR",
  TOP_UP_INVESTMENT_SUCCESS: "portfolio/TOP_UP_INVESTMENT_SUCCESS",
  EDIT_INVESTMENT_REQUEST: "portfolio/EDIT_INVESTMENT_REQUEST",
  EDIT_INVESTMENT_ERROR: "portfolio/EDIT_INVESTMENT_ERROR",
  EDIT_INVESTMENT_SUCCESS: "portfolio/EDIT_INVESTMENT_SUCCESS",
  EDIT_AMOUNT_INVESTMENT_REQUEST: "portfolio/EDIT_AMOUNT_INVESTMENT_REQUEST",
  EDIT_AMOUNT_INVESTMENT_ERROR: "portfolio/EDIT_AMOUNT_INVESTMENT_ERROR",
  EDIT_AMOUNT_INVESTMENT_SUCCESS: "portfolio/EDIT_AMOUNT_INVESTMENT_SUCCESS",
}